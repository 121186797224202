import React from 'react';
import Layout from 'components/layout';
import SeoComponent from 'components/seo';
import { Breadcrumb } from "components/ui";
import { Link } from "gatsby";
import { useStrapiTopicData, useSiteMetadataNavigationData } from 'hooks';

const isBrowser = typeof window !== "undefined"

const SitemapPage = ({ location }) => {
    const { allStrapiTopic } = useStrapiTopicData();
    const { site } = useSiteMetadataNavigationData();
    let thisLocationPathname = ''
    if (isBrowser) {
        thisLocationPathname = window.location.pathname;
    }
    const seo = {
        metaTitle: "Site Map"
    }
    const sitemapLinks = site.siteMetadata.navigation.slice(2)
return (
<Layout location={location}>
    <div className="grid-container">
        <SeoComponent title={seo.metaTitle}  />
        <Breadcrumb pathname={thisLocationPathname} customCurrentPage="Site Map" />
        <h1 className="usa-hero__heading" name="main-content" id="main-content">NCSACW Site Map</h1>
        <div className="unordered-list-style padding-bottom-5">
            <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/topics">Explore Topics</Link></li>
                <ul>
                    {allStrapiTopic?.nodes.map((topic, idx) => (
                    <div key={`site-map-topic-link-${idx}`} >
                        <li>
                            <Link to={`/topics/${topic.slug}`}>
                                {topic?.card_title ?? topic?.title}
                            </Link>
                        </li>
                        {topic?.series?.length > 0 && 
                        <ul>
                            {topic?.series?.map((series,idx)=>(
                                <li key = {`site-map-series-link-${idx}`}>
                                    <Link to={`/topics/${topic.slug}/${series.slug}`}>
                                        {series?.card_title ?? series?.title}
                                 </Link>
                                </li>
                            ))}
                        </ul>
                        }
                    </div>
                ))}
                </ul>
                {sitemapLinks.map((sitemapLink, idx)=>(
                    <div key = {`site-map-link-${idx}`}>
                        <li>
                            <Link to={`${sitemapLink.link}`}>
                                {sitemapLink.title}
                            </Link>
                        </li>
                        {sitemapLink.link === "/about-us" && 
                            <ul>
                                <li><Link to="/about-us/federal-sponsors">Federal Sponsors</Link></li>
                                <li><Link to="/about-us/contractors">Contractor Acknowledgments</Link></li>
                            </ul>
                        }
                        {sitemapLink.items.length > 1 && 
                        <ul>
                            {sitemapLink.items.map((subLink, idx) =>(
                                <div key = {`site-map-sub-link-${idx}`}>
                                <li>
                                    <Link to={`${subLink.link}`}>
                                        {subLink.text}
                                    </Link>
                                </li>
                                {subLink.link === "https://ncsacw.acf.hhs.gov/tutorials/Default.aspx" && 
                                <ul>
                                    <li><a href="https://ncsacw.acf.hhs.gov/tutorials/tutorialDesc.aspx?id=26">Tutorial Description for Substance Use Disorder Treatment Professionals</a></li>
                                    <li><a href="https://ncsacw.acf.hhs.gov/tutorials/tutorialDesc.aspx?id=27">Tutorial Description for Child Welfare Professionals</a></li>
                                    <li><a href="https://ncsacw.acf.hhs.gov/tutorials/tutorialDesc.aspx?id=28">Tutorial Description for Legal Professionals</a></li>
                                </ul>
                                }
                                </div>
                            ))}
                        </ul>
                        }
                    </div>
                ))}
            </ul>
        </div>
    </div>
</Layout>
)};

export default SitemapPage;
